import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { Fragment, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { posthogJobClick } from "../../../helpers/posthog";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateJobAppliedDialog } from "../../../redux/actions/dialog_action";
import {
  getJobAppliedAndBookmarkStatus,
  getSimilarApplicationJobs,
  saveJob,
  unSaveJob,
  updateSimilarJobs,
} from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import Button from "../../shared/SharedButton/SharedButton";
import SliderArrowNext from "../../shared/SharedSliderButtons/SliderArrowNext";
import SliderArrowPrev from "../../shared/SharedSliderButtons/SliderArrowPrev";
import {
  BookMarkFilledIconStyled,
  BookmarkBorderOutlinedIconStyled,
  ButtonContainer,
  CardActionAreaStyled,
  CardContentContainer,
  CardContentStyled,
  CardMediaStyled,
  CardStyled,
  CheckIconContainer,
  CheckIconStyled,
  CircularProgressStyled,
  CloseButtonContainer,
  CompanyNameContainer,
  ContentContainer,
  DescriptionContainer,
  DescriptionProfile,
  DescriptionStyled,
  DialogContentStyled,
  DialogStyled,
  HeaderProfile,
  HeaderStyled,
  IconButtonStyled,
  ImageContainer,
  JobCardSkeleton,
  JobTitle,
  JobTitleContainer,
  LinkStyled,
  RecommendedJobsContainer,
  SkeletonContentFirstRow,
  SkeletonContentSecondRow,
  SkeletonDiv,
  SliderStyled,
  TextSkeleton,
  TitleContainer,
} from "./styles";

let elementRefs = {}; // For tooltip display on truncated words

function JobAppliedDialog(props) {
  const [currentJobId, setCurrentJobId] = useState("");
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
  const [width, setWidth] = useState(0);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const [tooltipStatus, setTooltipStatus] = useState({});
  const [slidesToShow, setSlidesToShow] = useState(4);

  const dispatch = useDispatch();

  const compareSize = () => {
    // compute show tooltip status
    // false - enable tooltip
    // true - disable tooltip
    let cTooltipStatus = {};

    Object.keys(elementRefs).map((key) => {
      if (
        elementRefs[key] &&
        elementRefs[key].scrollWidth > elementRefs[key].clientWidth
      ) {
        cTooltipStatus[key] = false;
      } else {
        cTooltipStatus[key] = true;
      }
    });
    setTooltipStatus(cTooltipStatus);
    setWidth(window.innerWidth);
  };

  // Compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    window.addEventListener("resize", compareSize);

    if (!width) {
      setWidth(window.innerWidth);
    }

    if (
      props.similarApplicationJobs.length > 0 &&
      Object.keys(elementRefs).length == props.similarApplicationJobs.length
    ) {
      compareSize();
    }

    // Remove resize listener again on "componentWillUnmount"
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  useEffect(() => {
    // set number of slides based on breakpoints
    if (width <= 1024) {
      setSlidesToShow(2);
    } else if (width > 1024 && width <= 1280) {
      setSlidesToShow(3);
    } else if (width > 1280) {
      setSlidesToShow(4);
    }
  }, [width]);

  const handleClose = () => {
    props.updateJobAppliedDialog(false);
    setShowPrevArrow(false);
    setShowNextArrow(true);
  };

  useEffect(() => {
    if (props.showJobAppliedDialog) {
      store.getState().jobs.bookmarkedRecommendedJobs = [];
      setBookmarkedJobs([]);

      props.updateSimilarJobs([]).then(() => {
        let params = {
          first: 20,
          jobId: props.job.id,
          useFullUrl: true,
          country: props.selectedCountry,
        };
        props.getSimilarApplicationJobs(params);
      });
    }
  }, [props.showJobAppliedDialog]);

  const onTriggerSaveJob = (similarApplicationJob) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentJobId(similarApplicationJob.id);
    let params = {
      jobId: similarApplicationJob.id,
      canCancel: false,
    };
    props.getJobAppliedAndBookmarkStatus(params).then((response) => {
      if (response.bookmark) {
        if (props.unSaveJob) {
          props.unSaveJob(similarApplicationJob.id, "recommended-job");
        } else {
          dispatch(
            unSaveJob(
              checkAuthentication(),
              similarApplicationJob.id,
              "recommended-job"
            )
          ); // Additional param to determine origin of unsaved job for tracking purposes
        }
        setBookmarkedJobs(
          bookmarkedJobs.filter((jobId) => jobId !== similarApplicationJob.id)
        );
      } else {
        if (props.onFollowJobClicked) {
          props.onFollowJobClicked(similarApplicationJob.id, "recommended-job");
        } else {
          dispatch(
            saveJob(
              checkAuthentication(),
              similarApplicationJob.id,
              "recommended-job"
            )
          ); // Additional param to determine origin of saved job for tracking purposes
        }
        setBookmarkedJobs((bookmarkedJobs) => [
          ...bookmarkedJobs,
          similarApplicationJob.id,
        ]);
      }
      store.getState().jobs.bookmarkedRecommendedJobs = bookmarkedJobs;
    });
  };

  const slideChangeHandler = (currentSlide) => {
    let numSlides = Math.ceil(
      props.similarApplicationJobs?.length / slidesToShow
    );
    let currentSlideIndex = Math.ceil(currentSlide / slidesToShow) + 1;

    if (currentSlideIndex === numSlides) {
      setShowPrevArrow(true);
      setShowNextArrow(false);
    } else {
      setShowPrevArrow(true);
      setShowNextArrow(true);
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: <SliderArrowPrev />,
    nextArrow: <SliderArrowNext />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
    afterChange: slideChangeHandler,
  };

  const trackImpressionInView = (job, index) => {
    const cleanSalary = job?.salary
      ?.split("-")
      .map((item) => item.replaceAll("RM", ""));

    posthog.capture("job_impression", {
      origin: "job_application_popup",
      index: index,
      job_id: job?.id,
      job_title: job?.title,
      job_type: job?.jobType,
      job_discovery: "similar_jobs",
      spotlight: job?.spotlight,
      featured: job?.boosted,
      keyword: job?.keywordHighlight,
      scraped: job?.scraped,
      category: job?.category,
      job_slot_type: job?.jobSlotType,
      track_titles: job?.tracks?.map((track) => track.title) ?? [],
      company_id: job?.company.id,
      company_name: job?.company.name,
      location: job?.location,
      state_region: job?.stateRegion,
      hide_salary:
        !job?.salary ||
        job?.salary.toString().toLowerCase().includes("undisclosed")
          ? true
          : false,
      career_level: job?.careerLevel,
      min_years_experience: job?.minYearsExperience,
      max_years_experience: job?.maxYearsExperience,
      min_salary_range: parseInt(cleanSalary[0]) ?? 0,
      max_salary_range: parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
    });

    sendTrackingEvent({
      event: "CE_job-impression-job-application-popup",
      "job-id": job?.id,
      origin: "job_application_popup",
    });
  };

  return (
    <Fragment>
      <DialogStyled
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={props.showJobAppliedDialog}
      >
        <DialogContentStyled>
          <CloseButtonContainer>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </CloseButtonContainer>

          {props.origin === "applied-jobs-profile" ? null : (
            <ContentContainer>
              <TitleContainer>
                <CheckIconContainer>
                  <CheckIconStyled />
                </CheckIconContainer>
                <HeaderStyled>Thank you for applying!</HeaderStyled>
              </TitleContainer>
              <DescriptionContainer>
                {width > 748 ? (
                  <DescriptionStyled>
                    Your application will be submitted to the employer in 30
                    minutes. <br /> Review your application in your{" "}
                    <LinkStyled
                      href="/profile#job-application-history/?pending"
                      target="_blank"
                      rel="noopener"
                      id={"view-job-application-history-button"}
                    >
                      Job Application Status
                    </LinkStyled>
                    .
                  </DescriptionStyled>
                ) : (
                  <DescriptionStyled>
                    Your application will be submitted to the employer in 30
                    minutes. Review your application in your{" "}
                    <LinkStyled
                      href="/profile#job-application-history/?pending"
                      target="_blank"
                      rel="noopener"
                      id={"view-job-application-history-button"}
                    >
                      Job Application Status
                    </LinkStyled>
                    .
                  </DescriptionStyled>
                )}
              </DescriptionContainer>
            </ContentContainer>
          )}

          {/* Desktop web view skeleton loader */}
          {props.fetchingSimilarApplicationJobs && width > 748 ? (
            <SkeletonDiv>
              <SkeletonContentFirstRow>
                <TextSkeleton variant="text" width={250} height={32} />
                <TextSkeleton variant="text" width={200} height={32} />
              </SkeletonContentFirstRow>
              <SkeletonContentSecondRow>
                <JobCardSkeleton variant="rectangular" />
                <JobCardSkeleton variant="rectangular" />
                <JobCardSkeleton variant="rectangular" />
                <JobCardSkeleton variant="rectangular" />
              </SkeletonContentSecondRow>
            </SkeletonDiv>
          ) : null}

          {/* {props.similarApplicationJobs.length > 4 && width > 748 && ( */}
          {props.similarApplicationJobs.length > 0 &&
            !props.fetchingSimilarApplicationJobs &&
            width > 748 && (
              <RecommendedJobsContainer>
                {props.origin === "applied-jobs-profile" &&
                !props.fetchingSimilarApplicationJobs ? (
                  <Fragment>
                    <HeaderProfile variant="h6">
                      Similar jobs you might like
                    </HeaderProfile>
                    <DescriptionProfile variant="subtitle1">
                      Here to aid on your job discovery!
                    </DescriptionProfile>
                  </Fragment>
                ) : (
                  <HeaderStyled>View similar jobs you might like</HeaderStyled>
                )}
                <SliderStyled
                  {...settings}
                  $showPrevArrow={showPrevArrow}
                  $showNextArrow={showNextArrow}
                >
                  {props.similarApplicationJobs
                    .filter(function (filteredJob) {
                      return filteredJob.id !== props.job.id;
                    })
                    .map((similarApplicationJob, index) => {
                      const jobUrl = `/jobs/${similarApplicationJob.slug}`;
                      return (
                        <CardStyled key={index} elevation={4}>
                          <CardActionAreaStyled
                            href={jobUrl}
                            target="_blank"
                            rel="noopener"
                            onClick={() => {
                              Cookies.set(
                                "click-source",
                                "job_application_popup"
                              );

                              sendTrackingEvent({
                                event: "CE_click-job-job-application-popup",
                                "job-id": similarApplicationJob.id,
                                "job-title": similarApplicationJob.title,
                              });
                            }}
                          >
                            <ImageContainer>
                              <IconButtonStyled
                                id={"bookmark-icon-popup-button"}
                                aria-label="recommended-job-bookmark"
                                onClick={onTriggerSaveJob(
                                  similarApplicationJob
                                )}
                              >
                                {similarApplicationJob.bookmark ||
                                bookmarkedJobs.includes(
                                  similarApplicationJob.id
                                ) ? (
                                  props.unbookmarkJob &&
                                  similarApplicationJob.id == currentJobId ? (
                                    <CircularProgressStyled />
                                  ) : (
                                    <BookMarkFilledIconStyled />
                                  )
                                ) : props.bookmarkingJob &&
                                  similarApplicationJob.id == currentJobId ? (
                                  <CircularProgressStyled />
                                ) : (
                                  <BookmarkBorderOutlinedIconStyled />
                                )}
                              </IconButtonStyled>
                              <CardMediaStyled
                                component="img"
                                image={`https:${
                                  similarApplicationJob.image_thumb ||
                                  similarApplicationJob.imageThumb
                                }`}
                                height="150"
                              />
                            </ImageContainer>
                            <CardContentStyled>
                              <JobTitleContainer>
                                <InView
                                  onChange={(inView) =>
                                    inView === true &&
                                    trackImpressionInView(
                                      similarApplicationJob,
                                      index
                                    )
                                  }
                                >
                                  <Tooltip
                                    title={similarApplicationJob.title}
                                    placement="top"
                                    disableHoverListener={
                                      tooltipStatus[similarApplicationJob.id]
                                    }
                                    arrow
                                  >
                                    <JobTitle
                                      gutterBottom
                                      $title
                                      ref={(ref) => {
                                        elementRefs[similarApplicationJob.id] =
                                          ref;
                                      }}
                                    >
                                      {similarApplicationJob.title}
                                    </JobTitle>
                                  </Tooltip>
                                </InView>
                              </JobTitleContainer>
                              <CompanyNameContainer>
                                <JobTitle>
                                  {similarApplicationJob.company?.name}
                                </JobTitle>
                              </CompanyNameContainer>

                              <ButtonContainer>
                                <a
                                  href={jobUrl}
                                  target="_blank"
                                  rel="noopener"
                                  onClick={() => {
                                    posthogJobClick(
                                      similarApplicationJob,
                                      similarApplicationJob?.company,
                                      "",
                                      "job_application_popup",
                                      "similar_jobs"
                                    );

                                    sendTrackingEvent({
                                      event:
                                        "CE_click-job-job-application-popup",
                                      "job-id": similarApplicationJob.id,
                                      "job-title": similarApplicationJob.title,
                                    });
                                  }}
                                >
                                  <Button
                                    id={"view-now-job-button"}
                                    button_type={"SolidPurple"}
                                    desktop_width={"Medium"}
                                    bold={"false"}
                                    font_size={"caption"}
                                    variant="contained"
                                    color="primary"
                                  >
                                    View Now
                                  </Button>
                                </a>
                              </ButtonContainer>
                            </CardContentStyled>
                          </CardActionAreaStyled>
                        </CardStyled>
                      );
                    })}
                </SliderStyled>
              </RecommendedJobsContainer>
            )}

          {/* Mobile web view skeleton loader */}
          {props.fetchingSimilarApplicationJobs && width < 748 ? (
            <SkeletonDiv>
              <SkeletonContentFirstRow>
                <TextSkeleton variant="text" width={250} height={32} />
                <TextSkeleton variant="text" width={200} height={32} />
              </SkeletonContentFirstRow>
              <SkeletonContentSecondRow>
                <JobCardSkeleton variant="rectangular" />
              </SkeletonContentSecondRow>
            </SkeletonDiv>
          ) : null}

          {props.similarApplicationJobs > 0 &&
            !props.fetchingSimilarApplicationJobs &&
            width < 748 && (
              <RecommendedJobsContainer>
                {props.origin === "applied-jobs-profile" ? (
                  <Fragment>
                    <HeaderProfile variant="h6">
                      Similar jobs you might like
                    </HeaderProfile>
                    <DescriptionProfile variant="subtitle1">
                      Here to aid on your job discovery!
                    </DescriptionProfile>
                  </Fragment>
                ) : (
                  <HeaderStyled>View similar jobs you might like</HeaderStyled>
                )}
                <SliderStyled {...settings}>
                  {props.similarApplicationJobs
                    .filter(function (filteredJob) {
                      return (
                        filteredJob.id !== props.job.id &&
                        !filteredJob.haveApplied &&
                        !filteredJob.bookmark
                      );
                    })
                    .slice(0, 5)
                    .map((similarApplicationJob, index) => {
                      const jobUrl = `/jobs/${similarApplicationJob.slug}`;
                      return (
                        <CardStyled key={index} elevation={4}>
                          <ImageContainer>
                            <IconButtonStyled
                              id={"bookmark-icon-popup-button"}
                              aria-label="recommended-job-bookmark"
                              onClick={onTriggerSaveJob(similarApplicationJob)}
                            >
                              {similarApplicationJob.bookmark ||
                              bookmarkedJobs.includes(
                                similarApplicationJob.id
                              ) ? (
                                props.unbookmarkJob &&
                                similarApplicationJob.id == currentJobId ? (
                                  <CircularProgressStyled />
                                ) : (
                                  <BookMarkFilledIconStyled />
                                )
                              ) : props.bookmarkingJob &&
                                similarApplicationJob.id == currentJobId ? (
                                <CircularProgressStyled />
                              ) : (
                                <BookmarkBorderOutlinedIconStyled />
                              )}
                            </IconButtonStyled>
                            <CardMediaStyled
                              component="img"
                              image={`https:${
                                similarApplicationJob.image_thumb ||
                                similarApplicationJob.imageThumb
                              }`}
                              height="120"
                            />
                          </ImageContainer>
                          <CardContentStyled>
                            <CardContentContainer>
                              <JobTitleContainer>
                                <InView
                                  onChange={(inView) =>
                                    inView === true &&
                                    trackImpressionInView(
                                      similarApplicationJob,
                                      index
                                    )
                                  }
                                >
                                  <JobTitle
                                    gutterBottom
                                    $title
                                    ref={(ref) => {
                                      elementRefs[similarApplicationJob.id] =
                                        ref;
                                    }}
                                  >
                                    {similarApplicationJob.title}
                                  </JobTitle>
                                </InView>
                              </JobTitleContainer>
                              <CompanyNameContainer>
                                <JobTitle>
                                  {similarApplicationJob.company?.name}
                                </JobTitle>
                              </CompanyNameContainer>
                            </CardContentContainer>
                          </CardContentStyled>
                          <ButtonContainer>
                            <a
                              href={jobUrl}
                              target="_blank"
                              rel="noopener"
                              onClick={() => {
                                posthogJobClick(
                                  similarApplicationJob,
                                  similarApplicationJob?.company,
                                  "",
                                  "job_application_popup",
                                  "similar_jobs"
                                );
                              }}
                            >
                              <Button
                                id={"view-now-job-button"}
                                button_type={"SolidPurple"}
                                desktop_width={"Medium"}
                                bold={"false"}
                                font_size={"caption"}
                              >
                                View Now
                              </Button>
                            </a>
                          </ButtonContainer>
                        </CardStyled>
                      );
                    })}
                </SliderStyled>
              </RecommendedJobsContainer>
            )}
        </DialogContentStyled>
      </DialogStyled>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  showJobAppliedDialog: state.dialog.showJobAppliedDialog,
  bookmarkingJob: state.jobs.bookmarkingJob,
  unbookmarkJob: state.jobs.unbookmarkJob,
  bookmarkedRecommendedJobs: state.jobs.bookmarkedRecommendedJobs,
  similarApplicationJobs: state.jobs.similarApplicationJobs,
  fetchingSimilarApplicationJobs: state.jobs.fetchingSimilarApplicationJobs,
  job: state.jobs.job,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobAppliedDialog: bindActionCreators(
      updateJobAppliedDialog,
      dispatch
    ),

    getJobAppliedAndBookmarkStatus: bindActionCreators(
      getJobAppliedAndBookmarkStatus,
      dispatch
    ),
    getSimilarApplicationJobs: bindActionCreators(
      getSimilarApplicationJobs,
      dispatch
    ),
    updateSimilarJobs: bindActionCreators(updateSimilarJobs, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAppliedDialog);
